
import VideoPlayerStarter from "./video-player-starter-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class VideoV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    VideoPlayerStarter.setupVideo(this.element)
  }



}

registerComponent('Video/V1', VideoV1)
window["VideoV1"] = VideoV1

