
import "./video-v1"
import "./modal-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class VideoPopupV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.modal = this.getComponent("Modal/V1");
    this.video = this.getComponent("Video/V1")
    this.isVideoInitialized = false;
    this.setupVideoPopupListenters();
  }

  setupVideoPopupListenters() {
    const videoType = this.video.videoType

    const isWistia = videoType === "wistia";
    const isVimeo = videoType === "vimeo";
    const isYoutube = videoType === "youtube";
    const isHTML5 = videoType === "html5";
    const isVoomly = videoType === "voomly";
    const isCustomScript = videoType === "custom_script"

    const popupImage = this.element.querySelector(".elImage");

    this.video.element.addEventListener("click", (evt) => {
      evt.stopPropagation();
    })

    popupImage.addEventListener("click", () => {
      this.modal.element.style.display = "flex";
      if (isHTML5 || isVoomly) {
        const videoElement = this.video.element.querySelector("video");
        if (this.isVideoInitialized) {
          videoElement.play()
        } else {
          videoElement.addEventListener('loadeddata', () => {
            videoElement.play();
          })
          this.isVideoInitialized = true;
        }
      } else if (isCustomScript) {
        if (this.isVideoInitialized) {
          const videoElement = this.video.element.querySelector("video");
          videoElement.play();
        } else {
          const scriptElement = this.video.element.querySelector("script");
          scriptElement.setAttribute("src", scriptElement.getAttribute("data-src"));
          this.isVideoInitialized = true;
          const videoElement = this.video.element.querySelector("video");
          videoElement.addEventListener('loadeddata', () => {
            videoElement.play();
          })
        }
      } else {
        const videoElement = this.video.element.querySelector("iframe");
        let src = videoElement.getAttribute("data-src");
        if (isYoutube) {
          src = src.replace("autoplay=0", "mute=1&autoplay=1");
        } else if (isVimeo) {
          src = src.replace("autoplay=0", "muted=1&autoplay=1");
        } else if (isWistia) {
          src = src.replace("autoplay=0", "muted=true&autoplay=1");
        }
        videoElement.setAttribute("src", src);
      }
    });

    this.modal.element.addEventListener("click", () => {
      this.modal.element.style.display = "none";
      if (isHTML5 || isVoomly || isCustomScript) {
        const videoElement = this.video.element.querySelector("video");
        videoElement.pause();
        videoElement.currentTime = 0;
      } else {
        const videoElement = this.video.element.querySelector("iframe");
        let src = videoElement.getAttribute("src");
        if (isYoutube) {
          src = src.replace("mute=1&autoplay=1", "autoplay=0");
        } else if (isVimeo) {
          src = src.replace("muted=1&autoplay=1", "autoplay=0");
        } else if (isWistia) {
          src = src.replace("muted=true&autoplay=1", "autoplay=0");
        }
        videoElement.setAttribute("src", src);
      }
    });
  }



}

registerComponent('VideoPopup/V1', VideoPopupV1)
window["VideoPopupV1"] = VideoPopupV1

