
import VideoPlayerStarter from "./video-player-starter-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class VideoV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    if (this.element.querySelector('video')) {
      VideoPlayerStarter.setupVideo(this.element)
    }
  }



}

registerComponent('Video/V2', VideoV2)
window["VideoV2"] = VideoV2

